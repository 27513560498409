<template>
  <v-app-bar
    color="primary"
    clipped-left
    app
    fixed>

    <v-app-bar-nav-icon dark @click.stop="$store.commit('sidebar/toggleSidebar')"></v-app-bar-nav-icon>

    <v-toolbar-title style="width: 300px">
      <router-link tag="span" :to="{name: 'dashboard'}" class="hidden-sm-and-down" style="color: white; cursor: pointer;">Roote Administration</router-link>
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items>

      <!-- Logout -->
      <v-btn icon dark color="primary">
        <v-icon color="white" @click="$store.dispatch('logout')">fas fa-sign-out-alt</v-icon>
      </v-btn>

    </v-toolbar-items>



  </v-app-bar>
</template>

<script>
  export default
  {
    name: "Topbar",

    data()
    {
      return {
      }
    },

    methods:
    {

    }
  }
</script>

<style scoped>

</style>